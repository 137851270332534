@import-normalize; /* bring in normalize.css styles */

/* rest of app styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

/* outline */
button,
input,
textarea {
  outline: none;
  border: solid 0 white;
}

ul {
  margin: 0;
  padding-inline-start: 20px;
}

div {
  outline: none;
  border: solid 0 white;
  cursor: default;
  /*user-select: none;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

.line-through {
  text-decoration: line-through;
}

.white {
  color: white;
}

.dark-blue {
  color: #1b315d;
}

.light-blue {
  color: #9cbee1;
}

.taxcenter-blue {
  color: #1b1d91;
}

.blue {
  color: #5e8ec0;
}

.mint {
  color: #06b3cb;
}

.blue-green {
  color: #00b1d1;
}

.blue-green-light {
  color: #00d8ff;
}

.gray-blue {
  color: #8a8bb7;
}

.dark-gray {
  color: #34373a;
}

.light-gray {
  color: #d3d3d3;
}

.gray {
  color: #b8a891;
}

.blue-gray {
  color: #a6adaf;
}

.navy {
  color: #002933;
}

.custom-gray {
  color: #e7e7e6;
}

.font-size14 {
  font-size: .72vw;
}

.font-size15 {
  font-size: .78vw;
}

.font-size16 {
  font-size: .83vw;
}

.font-size17 {
  font-size: .88vw;
}

.font-size18 {
  font-size: .93vw;
}

.font-size19 {
  font-size: .98vw;
}

.font-size22 {
  font-size: 1.15vw;
}

.font-size23 {
  font-size: 1.2vw;
}

.font-size28 {
  font-size: 1.46vw;
}

.font-size38 {
  font-size: 1.97vw;
}

.font-size40 {
  font-size: 2.08vw;
}

.font-size50 {
  font-size: 2.6vw;
}

.spoqa730 {
  font-family: Spoqa, serif;
  font-weight: 300;
}

.spoqa740 {
  font-family: Spoqa, serif;
  font-weight: 400;
}

.spoqa750 {
  font-family: Spoqa, serif;
  font-weight: 500;
}

.spoqa770 {
  font-family: Spoqa, serif;
  font-weight: 700;
}

.roboto-black {
  font-family: RobotoBlack;
}

.roboto-regular {
  font-family: RobotoRegular;
}

.roboto-medium {
  font-family: RobotoMedium;
}

.rixmgo-extrabold {
  font-family: RixMGoExtraBold;
}

.rixmgo-heavy {
  font-family: RixMGoHeavy;
}

.rixmgo-light {
  font-family: RixMGoLight;
}

.rixmgo-medium {
  font-family: RixMGoMedium;
}

.rixmgo-regular {
  font-family: RixMGoRegular;
}

.rixmgo-semibold {
  font-family: RixMGoSemiBold;
}

.rixmgo-thin {
  font-family: RixMGoThin;
}

.rixmgo-ultraLight {
  font-family: RixMGoUltraLight;
}


.flex {
  display: flex;
  flex-wrap: wrap;
}

.reverse {
  transform: rotate(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.bottom-line {
  border-bottom: solid 1px #9cbee1;
}

.bottom-line-bold {
  border-bottom: solid 1.5px #9cbee1;
}

.bottom-line-mint-bold {
  border-bottom: solid 1.5px #06b3cb;
}

.button {
  cursor: pointer;
}
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  border-radius: inherit;
}


input {
  -webkit-appearance: none;
}

textarea {
  -webkit-appearance: none;
}

input.taxcenter-blue::placeholder {
  color: #babbd7;
}

textarea.taxcenter-blue::placeholder {
  color: #babbd7;
}

input.custom::placeholder {
  opacity: 0.4;
}

textarea.custom::placeholder {
  opacity: 0.4;
}

.no-select {
  outline: none;
}

.break.flex {
  flex-basis: 100%;
  height: 0;
}
.popup.background {
  width: 100vw;
  height: 100vh;

  position: fixed;
  left: 0;
  top: 0;

  z-index: 500;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;
}

.popup.background.transparent {
  width: 100vw;

  position: fixed;
  left: 0;
  top: 0;

  z-index: 100;
  background-color: rgba(0, 0, 0, 0);
}

.popup.background.touchable {
  background-color: rgba(0, 0, 0, 0);
  z-index: -1;
  cursor: default;
}

.popup.background.menu {
  z-index: 45;
  justify-content: flex-end;
}

