.kakao.window {
  width: 458px;
  /*min-height: 280px;*/
  background-color: white;
  padding: 38px 45px 40px 45px;
  position: relative;
}

.kakao.window.hidden {
  margin-top: -30px;
  opacity: 0;
}

.kakao.title {
  display: flex;
  align-items: center;
  font-family: Spoqa;
  font-weight: 300;
  font-size: 28px;
  color: #3a1e0b;
}

.kakao.title.space {
  margin-bottom: 24px;
}

.kakao.input.phone {
  width: 368px;
  height: 60px;
  font-size: 16px;
  border: solid 1px #bcb6b2;
  padding: 0 27px;
  margin-bottom: 15px;
  display: flex;
}

.kakao.input.phone::placeholder {
  color: #3b1f0a;
}

.kakao.comment {
  line-height: 24px;
  font-size: 14px;
  color: #3b1f0a;
}

.kakao.comment.success {
  line-height: 30px;
  font-size: 16px;
}

.kakao.button {
  width: 100%;
  height: 60px;
  border: none;
  background-color: #ffe900;
  font-size: 16px;
  color: #3a1e0b;
}

.kakao.button.active {
  border: solid 1px #3a1e0b;
  background-color: #fff;
  color: #3a1e0b;
  margin: 0;
}

.kakao.phone.checkcircle {
  font-size: 28px;
  color: #f0b100;
}

.close {
  width: 21px;
  height: 21px;
  border: none;
  background-color: unset;
  position: absolute;
  right: 20px;
  top: 20px;
}

.bottom-space12 {
  margin-bottom: 12px;
}

.bottom-space15 {
  margin-bottom: 12px;
}

.bottom-space25 {
  margin-bottom: 25px;
}

.bottom-space31 {
  margin-bottom: 31px;
}


