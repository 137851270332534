.customdot-css {
    width: 60px;
    list-style: none;
    text-align: end;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
}

.customdot-css li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
}

.customdot-css li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
}

.customdot-css li button:before {
    font-size: 2.7rem;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .75;
    color: #6D6968;
}

.customdot-css li.slick-active button:before {
    opacity: .75;
    color: #ffffff;
}
